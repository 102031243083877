<template>
  <section>
    <folha v-for="(elementos, indexFolha) in tamanhoElemento" :key="indexFolha">
      <br v-if="indexFolha != 0" />
      <br v-if="indexFolha != 0" />
      <br v-if="indexFolha != 0" />
      <br v-if="indexFolha != 0" />

      <div v-if="indexFolha === 0">
        <br />
        <br />
        <br />
        <br />
        <div class="header maiuscula" style="">
          <h3><strong>NOME:</strong> {{ paciente.nome }}</h3>
          <h3><strong>DATA DO EXAME:</strong> {{ consulta.data }}</h3>
          <br />
        </div>

        <br />
        <br />
        <h3>ULTRASSONOGRAFIA OBSTÉTRICA DE PRIMEIRO TRIMESTRE</h3>
        <br />
        <br />
      </div>

      <div v-for="(elemento, indexElemento) in elementos" :key="indexElemento">
        <h4 v-if="titulos.includes(elemento)">
          <p></p>
          <p></p>
          <p></p>
          <p></p>

          {{ elemento }}
          <p></p>
          
        </h4>

        <h3 v-else-if="elemento == 'feto0'">
          <strong> Embrião 1</strong>
        </h3>
        <h3 v-else-if="elemento === 'feto1'">
          <strong> Embrião 2</strong>
        </h3>
        <h3 v-else-if="elemento === 'feto2'">
          <strong> Embrião 3</strong>
        </h3>
        <h3 v-else-if="elemento === 'feto3'">
          <strong> Embrião 4</strong>
        </h3>
        <h3 v-else-if="elemento === 'feto4'">
          <strong> Embrião 5</strong>
        </h3>
        <p v-else-if="elemento === 'espaço'"></p>

        <div v-else-if="elemento == 'SacoGest0'">
          <strong>Saco gestacional:</strong>
          {{
            `${laudo[0].sacoGestacional} ${
              laudo[0].sacoGestacional2
                ? 'inserido na lâmina endometrial ' +
                  laudo[0].sacoGestacional2 +
                  ' da cavidade uterina, '
                : ''
            } com paredes
              ${laudo[0].sacoGestacional3}.`
          }}{{
            laudo[0].camposExibidos.exibeDiametro && laudo[0].diametroMedio
              ? ` Diâmetro médio de ${laudo[0].diametroMedio} mm.`
              : ''
          }}
        
          
        </div>
        <div v-else-if="elemento == 'SacoGest1'">
          <strong>Saco gestacional:</strong>
          {{
            ` ${laudo[1].sacoGestacional} ${
              laudo[1].sacoGestacional2
                ? 'inserido na lâmina endometrial ' +
                  laudo[1].sacoGestacional2 +
                  ' da cavidade uterina, '
                : ''
            } com paredes
              ${laudo[1].sacoGestacional3}.`
          }}{{
            laudo[1].camposExibidos.exibeDiametro && laudo[1].diametroMedio
              ? ` Diâmetro médio de ${laudo[1].diametroMedio} mm.`
              : ''
          }}
         
        </div>
        <div v-else-if="elemento == 'SacoGest2'">
          <strong>Saco gestacional:</strong>
          {{
            ` ${laudo[2].sacoGestacional}, único, ${
              laudo[2].sacoGestacional2
                ? 'inserido na lâmina endometrial ' +
                  laudo[2].sacoGestacional2 +
                  ' da cavidade uterina, '
                : ''
            } com paredes
              ${laudo[2].sacoGestacional3}.`
          }}{{
            laudo[2].camposExibidos.exibeDiametro && laudo[2].diametroMedio
              ? ` Diâmetro médio de ${laudo[2].diametroMedio} mm.`
              : ''
          }}
        
        </div>
        <div v-else-if="elemento == 'SacoGest3'">
          <strong>Saco gestacional:</strong>
          {{
            `${laudo[3].sacoGestacional}, único, ${
              laudo[3].sacoGestacional2
                ? 'inserido na lâmina endometrial ' +
                  laudo[3].sacoGestacional2 +
                  ' da cavidade uterina, '
                : ''
            } com paredes
              ${laudo[3].sacoGestacional3}.`
          }}{{
            laudo[3].camposExibidos.exibeDiametro && laudo[3].diametroMedio
              ? ` Diâmetro médio de ${laudo[3].diametroMedio} mm.`
              : ''
          }}
        
        </div>
        <div v-else-if="elemento == 'SacoGest4'">
          <strong>Saco gestacional: </strong>
          {{
            `${laudo[4].sacoGestacional}, único, ${
              laudo[4].sacoGestacional2
                ? 'inserido na lâmina endometrial ' +
                  laudo[4].sacoGestacional2 +
                  ' da cavidade uterina, '
                : ''
            } com paredes
              ${laudo[4].sacoGestacional3}.`
          }}{{
            laudo[4].camposExibidos.exibeDiametro && laudo[4].diametroMedio
              ? ` Diâmetro médio de ${laudo[4].diametroMedio} mm.`
              : ''
          }}
          
        </div>
        <div v-else-if="elemento == 'ute0'">
          
          <strong>Útero:</strong
          >{{
            laudo[0].camposExibidos.exibeUteroSituado && laudo[0].uteroSituado
              ? ` em ${laudo[0].uteroSituado}.`
              : ''
          }}{{
            laudo[0].camposExibidos.exFraseSinais
              ? ` Colo uterino e canal endocervical ${
                  laudo[0].uteroAnormal ? laudo[0].uteroAnormal : ''
                }${
                  laudo[0].polipoEndocervical
                    ? ' medindo ' + laudo[0].polipoEndocervical + ' mm'
                    : ''
                }.`
              : ''
          }}
        </div>
        <div v-else-if="elemento == 'ute1'">
          
          <strong>Útero:</strong
          >{{
            laudo[1].camposExibidos.exibeUteroSituado && laudo[1].uteroSituado
              ? ` em ${laudo[1].uteroSituado}.`
              : ''
          }}{{
            laudo[1].camposExibidos.exFraseSinais
              ? ` Colo uterino e canal endocervical ${
                  laudo[1].uteroAnormal ? laudo[1].uteroAnormal : ''
                }${
                  laudo[1].polipoEndocervical
                    ? ' medindo ' + laudo[1].polipoEndocervical + ' mm'
                    : ''
                }.`
              : ''
          }}
        </div>
        <div v-else-if="elemento == 'ute2'">
          
          <strong>Útero:</strong
          >{{
            laudo[2].camposExibidos.exibeUteroSituado && laudo[2].uteroSituado
              ? ` em ${laudo[2].uteroSituado}.`
              : ''
          }}{{
            laudo[2].camposExibidos.exFraseSinais
              ? ` Colo uterino e canal endocervical ${
                  laudo[2].uteroAnormal ? laudo[2].uteroAnormal : ''
                }${
                  laudo[2].polipoEndocervical
                    ? ' medindo ' + laudo[2].polipoEndocervical + ' mm'
                    : ''
                }.`
              : ''
          }}
        </div>
        <div v-else-if="elemento == 'ute3'">
          
          <strong>Útero:</strong
          >{{
            laudo[3].camposExibidos.exibeUteroSituado && laudo[3].uteroSituado
              ? ` em ${laudo[3].uteroSituado}.`
              : ''
          }}{{
            laudo[3].camposExibidos.exFraseSinais
              ? ` Colo uterino e canal endocervical ${
                  laudo[3].uteroAnormal ? laudo[3].uteroAnormal : ''
                }${
                  laudo[3].polipoEndocervical
                    ? ' medindo ' + laudo[3].polipoEndocervical + ' mm'
                    : ''
                }.`
              : ''
          }}
        </div>
        <div v-else-if="elemento == 'ute4'">
         
          <strong>Útero:</strong
          >{{
            laudo[4].camposExibidos.exibeUteroSituado && laudo[4].uteroSituado
              ? ` em ${laudo[4].uteroSituado}.`
              : ''
          }}{{
            laudo[4].camposExibidos.exFraseSinais
              ? ` Colo uterino e canal endocervical ${
                  laudo[4].uteroAnormal ? laudo[4].uteroAnormal : ''
                }${
                  laudo[4].polipoEndocervical
                    ? ' medindo ' + laudo[4].polipoEndocervical + ' mm'
                    : ''
                }.`
              : ''
          }}
        </div>
        <div v-else-if="elemento == 'Ovarios'">
          <b>Ovários</b>
        </div>
        <div v-else-if="elemento == 'Informações adicionais'">
          <b>Informações adicionais</b>
        </div>
        <div v-else-if="elemento == 'OvaDir0'">
          <strong>Direito:</strong>
          {{
            laudo[0].ovaDir === 'corpo lúteo hemorrágico medindo'
              ? laudo[0].ovaDir +
                ' ' +
                laudo[0].ovarioDireitoUm +
                ' x ' +
                laudo[0].ovarioDireitoDois +
                ' x ' +
                laudo[0].ovarioDireitoTres +
                ' cm de diâmetro' +
                (laudo[0].ovarioDireitoQuatro
                  ? ', com volume de ' + laudo[0].ovarioDireitoQuatro + ' cm³'
                  : '')
              : (laudo[0].ovaDir == 'Outro'? laudo[0].ovaDirOutro : laudo[0].ovaDir)
          }}.
        </div>
        <div v-else-if="elemento == 'OvaDir1'">
          <strong>Direito:</strong>
          {{
            laudo[1].ovaDir === 'corpo lúteo hemorrágico medindo'
              ? laudo[1].ovaDir +
                ' ' +
                laudo[1].ovarioDireitoUm +
                ' x ' +
                laudo[1].ovarioDireitoDois +
                ' x ' +
                laudo[1].ovarioDireitoTres +
                ' cm de diâmetro' +
                (laudo[1].ovarioDireitoQuatro
                  ? ', com volume de ' + laudo[1].ovarioDireitoQuatro + ' cm³'
                  : '')
              : (laudo[1].ovaDir == 'Outro'? laudo[1].ovaDirOutro : laudo[1].ovaDir)
          }}.
        </div>
        <div v-else-if="elemento == 'OvaDir2'">
          <strong>Direito:</strong>
          {{
            laudo[2].ovaDir === 'corpo lúteo hemorrágico medindo'
              ? laudo[2].ovaDir +
                ' ' +
                laudo[2].ovarioDireitoUm +
                ' x ' +
                laudo[2].ovarioDireitoDois +
                ' x ' +
                laudo[2].ovarioDireitoTres +
                ' cm de diâmetro' +
                (laudo[2].ovarioDireitoQuatro
                  ? ', com volume de ' + laudo[2].ovarioDireitoQuatro + ' cm³'
                  : '')
              : (laudo[2].ovaDir == 'Outro'? laudo[2].ovaDirOutro : laudo[2].ovaDir)
          }}.
        </div>
        <div v-else-if="elemento == 'OvaDir3'">
          <strong>Direito:</strong>
          {{
            laudo[3].ovaDir === 'corpo lúteo hemorrágico medindo'
              ? laudo[3].ovaDir +
                ' ' +
                laudo[3].ovarioDireitoUm +
                ' x ' +
                laudo[3].ovarioDireitoDois +
                ' x ' +
                laudo[3].ovarioDireitoTres +
                ' cm de diâmetro' +
                (laudo[3].ovarioDireitoQuatro
                  ? ', com volume de ' + laudo[3].ovarioDireitoQuatro + ' cm³'
                  : '')
              : (laudo[3].ovaDir == 'Outro'? laudo[3].ovaDirOutro : laudo[3].ovaDir)
          }}.
        </div>
        <div v-else-if="elemento == 'OvaDir4'">
          <strong>Direito:</strong>
          {{
            laudo[4].ovaDir === 'corpo lúteo hemorrágico medindo'
              ? laudo[4].ovaDir +
                ' ' +
                laudo[4].ovarioDireitoUm +
                ' x ' +
                laudo[4].ovarioDireitoDois +
                ' x ' +
                laudo[4].ovarioDireitoTres +
                ' cm de diâmetro' +
                (laudo[4].ovarioDireitoQuatro
                  ? ', com volume de ' + laudo[4].ovarioDireitoQuatro + ' cm³'
                  : '')
              : (laudo[4].ovaDir == 'Outro'? laudo[4].ovaDirOutro : laudo[4].ovaDir)
          }}.
        </div>
        <div v-else-if="elemento == 'OvaEsq0'">
          <strong>Esquerdo: </strong>
          {{
            laudo[0].ovaEsq === 'corpo lúteo hemorrágico medindo'
              ? laudo[0].ovaEsq +
                ' ' +
                laudo[0].ovarioEsqUm +
                ' x ' +
                laudo[0].ovarioEsqDois +
                ' x ' +
                laudo[0].ovarioEsqTres +
                ' cm de diâmetro' +
                (laudo[0].ovarioEsqQuatro
                  ? ', com volume de ' + laudo[0].ovarioEsqQuatro + ' cm³'
                  : '')
              : (laudo[0].ovaEsq ==  'Outro'? laudo[0].ovaEsqOutro : laudo[0].ovaEsq)
          }}.
        </div>
        <div v-else-if="elemento == 'OvaEsq1'">
          <strong>Esquerdo: </strong>
          {{
            laudo[1].ovaEsq === 'corpo lúteo hemorrágico medindo'
              ? laudo[1].ovaEsq +
                ' ' +
                laudo[1].ovarioEsqUm +
                ' x ' +
                laudo[1].ovarioEsqDois +
                ' x ' +
                laudo[1].ovarioEsqTres +
                ' cm de diâmetro' +
                (laudo[1].ovarioEsqQuatro
                  ? ', com volume de ' + laudo[1].ovarioEsqQuatro + ' cm³'
                  : '')
              : (laudo[1].ovaEsq ==  'Outro'? laudo[1].ovaEsqOutro : laudo[1].ovaEsq)
          }}.
        </div>
        <div v-else-if="elemento == 'OvaEsq2'">
          <strong>Esquerdo: </strong>
          {{
            laudo[2].ovaEsq === 'corpo lúteo hemorrágico medindo'
              ? laudo[2].ovaEsq +
                ' ' +
                laudo[2].ovarioEsqUm +
                ' x ' +
                laudo[2].ovarioEsqDois +
                ' x ' +
                laudo[2].ovarioEsqTres +
                ' cm de diâmetro' +
                (laudo[2].ovarioEsqQuatro
                  ? ', com volume de ' + laudo[2].ovarioEsqQuatro + ' cm³'
                  : '')
              : (laudo[2].ovaEsq ==  'Outro'? laudo[2].ovaEsqOutro : laudo[2].ovaEsq)
          }}.
        </div>
        <div v-else-if="elemento == 'OvaEsq3'">
          <strong>Esquerdo: </strong>
          {{
            laudo[3].ovaEsq === 'corpo lúteo hemorrágico medindo'
              ? laudo[3].ovaEsq +
                ' ' +
                laudo[3].ovarioEsqUm +
                ' x ' +
                laudo[3].ovarioEsqDois +
                ' x ' +
                laudo[3].ovarioEsqTres +
                ' cm de diâmetro' +
                (laudo[3].ovarioEsqQuatro
                  ? ', com volume de ' + laudo[3].ovarioEsqQuatro + ' cm³'
                  : '')
              : (laudo[3].ovaEsq ==  'Outro'? laudo[3].ovaEsqOutro : laudo[3].ovaEsq)
          }}.
        </div>
        <div v-else-if="elemento == 'OvaEsq4'">
          <strong>Esquerdo: </strong>
          {{
            laudo[4].ovaEsq === 'corpo lúteo hemorrágico medindo'
              ? laudo[4].ovaEsq +
                ' ' +
                laudo[4].ovarioEsqUm +
                ' x ' +
                laudo[4].ovarioEsqDois +
                ' x ' +
                laudo[4].ovarioEsqTres +
                ' cm de diâmetro' +
                (laudo[4].ovarioEsqQuatro
                  ? ', com volume de ' + laudo[4].ovarioEsqQuatro + ' cm³'
                  : '')
              : (laudo[4].ovaEsq ==  'Outro'? laudo[4].ovaEsqOutro : laudo[4].ovaEsq)
          }}.
        </div>

        <div v-else-if="elemento == 'VesVit0'">
          <strong>Vesícula vitelínica </strong
          >{{
            laudo[0].camposExibidos.exibevesiculaVitelinica &&
            laudo[0].vesiculaVitelinica &&
            laudo[0].vesiculaContornos
              ? ` ${laudo[0].vesiculaVitelinica}, ${laudo[0].vesiculaContornos}`
              : ''
          }}{{
            laudo[0].camposExibidos.exibeConteudo && laudo[0].vesiculaConteudo
              ? `, conteúdo ${laudo[0].vesiculaConteudo}`
              : ''
          }}{{
            laudo[0].camposExibidos.exibeVesiculaDiametro &&
            laudo[0].vesiDiametro
              ? `, medindo ${laudo[0].vesiDiametro} mm de diâmetro.`
              : '.'
          }}

         
        </div>
        <div v-else-if="elemento == 'VesVit1'">
          <strong>Vesícula vitelínica </strong
          >{{
            laudo[1].camposExibidos.exibevesiculaVitelinica &&
            laudo[1].vesiculaVitelinica &&
            laudo[1].vesiculaContornos
              ? ` ${laudo[1].vesiculaVitelinica}, ${laudo[1].vesiculaContornos}`
              : ''
          }}{{
            laudo[1].camposExibidos.exibeConteudo && laudo[1].vesiculaConteudo
              ? `, conteúdo ${laudo[1].vesiculaConteudo}`
              : ''
          }}{{
            laudo[1].camposExibidos.exibeVesiculaDiametro &&
            laudo[1].vesiDiametro
              ? `, medindo ${laudo[1].vesiDiametro} mm de diâmetro.`
              : '.'
          }}

          
        </div>
        <div v-else-if="elemento == 'VesVit2'">
          <p></p>
          <p></p>
          <strong>Vesícula vitelínica </strong
          >{{
            laudo[2].camposExibidos.exibevesiculaVitelinica &&
            laudo[2].vesiculaVitelinica &&
            laudo[2].vesiculaContornos
              ? ` ${laudo[2].vesiculaVitelinica}, ${laudo[2].vesiculaContornos}`
              : ''
          }}{{
            laudo[2].camposExibidos.exibeConteudo && laudo[2].vesiculaConteudo
              ? `, conteúdo ${laudo[2].vesiculaConteudo}`
              : ''
          }}{{
            laudo[2].camposExibidos.exibeVesiculaDiametro &&
            laudo[2].vesiDiametro
              ? `, medindo ${laudo[2].vesiDiametro} mm de diâmetro.`
              : '.'
          }}

        
        </div>
        <div v-else-if="elemento == 'VesVit3'">
        
          <strong>Vesícula vitelínica </strong
          >{{
            laudo[3].camposExibidos.exibevesiculaVitelinica &&
            laudo[3].vesiculaVitelinica &&
            laudo[3].vesiculaContornos
              ? ` ${laudo[3].vesiculaVitelinica}, ${laudo[3].vesiculaContornos}`
              : ''
          }}{{
            laudo[3].camposExibidos.exibeConteudo && laudo[3].vesiculaConteudo
              ? `, conteúdo ${laudo[3].vesiculaConteudo}`
              : ''
          }}{{
            laudo[3].camposExibidos.exibeVesiculaDiametro &&
            laudo[3].vesiDiametro
              ? `, medindo ${laudo[3].vesiDiametro} mm de diâmetro.`
              : '.'
          }}

        </div>
        <div v-else-if="elemento == 'VesVit4'">
          <p></p>
          <p></p>
          <strong>Vesícula vitelínica </strong
          >{{
            laudo[4].camposExibidos.exibevesiculaVitelinica &&
            laudo[4].vesiculaVitelinica &&
            laudo[4].vesiculaContornos
              ? ` ${laudo[4].vesiculaVitelinica}, ${laudo[4].vesiculaContornos}`
              : ''
          }}{{
            laudo[4].camposExibidos.exibeConteudo && laudo[4].vesiculaConteudo
              ? `, conteúdo ${laudo[4].vesiculaConteudo}`
              : ''
          }}{{
            laudo[4].camposExibidos.exibeVesiculaDiametro &&
            laudo[4].vesiDiametro
              ? `, medindo ${laudo[4].vesiDiametro} mm de diâmetro.`
              : ''
          }}

        </div>
        <div v-else-if="elemento == 'SacoAmn'">
         
          <strong>Saco amniótico</strong> filiforme junto ao embrião.
        </div>
        <div v-else-if="elemento == 'SacoAmnFeto'">
         
         <strong>Saco amniótico</strong> filiforme junto ao {{laudo[0].fraseSinaisFetEmb}}.
       </div>

        <div v-else-if="elemento == 'DopCol0'">
          <strong>Doppler colorido </strong> {{ laudo[0].dopplerCol }}.
        </div>
        <div v-else-if="elemento == 'DopCol1'">
          <strong>Doppler colorido </strong> {{ laudo[1].dopplerCol }}.
        </div>
        <div v-else-if="elemento == 'DopCol2'">
          <strong>Doppler colorido </strong> {{ laudo[2].dopplerCol }}.
        </div>
        <div v-else-if="elemento == 'DopCol3'">
          <strong>Doppler colorido </strong> {{ laudo[3].dopplerCol }}.
        </div>
        <div v-else-if="elemento == 'DopCol4'">
          <strong>Doppler colorido </strong> {{ laudo[4].dopplerCol }}.
        </div>

        <div v-else-if="elemento == 'VesiVite0'">
          
          <strong>Vesícula vitelínica </strong>
          {{ laudo[0].vesiculaVitelinica }}.
        </div>
        <div v-else-if="elemento == 'VesiVite1'">
          
          <strong>Vesícula vitelínica </strong>
          {{ laudo[1].vesiculaVitelinica }}.
        </div>
        <div v-else-if="elemento == 'VesiVite2'">
        
          <strong>Vesícula vitelínica </strong>
          {{ laudo[2].vesiculaVitelinica }}.
        </div>
        <div v-else-if="elemento == 'VesiVite3'">
       
          <strong>Vesícula vitelínica </strong>
          {{ laudo[3].vesiculaVitelinica }}.
        </div>
        <div v-else-if="elemento == 'VesiVite4'">
        
          <strong>Vesícula vitelínica </strong>
          {{ laudo[4].vesiculaVitelinica }}.
        </div>

        <div v-else-if="elemento == 'IndicaExame0'">
          <strong>Indicação do exame:</strong> {{ laudo[0].indicacaoExame == 'Outros'? laudo[0].indicacaoExameOutros: laudo[0].indicacaoExame }}.
        </div>
        <div v-else-if="elemento == 'IndicaExame1'">
          <strong>Indicação do exame:</strong> {{ laudo[1].indicacaoExame == 'Outros'? laudo[1].indicacaoExameOutros: laudo[1].indicacaoExame}}.
        </div>
        <div v-else-if="elemento == 'IndicaExame2'">
          <strong>Indicação do exame:</strong> {{ laudo[2].indicacaoExame == 'Outros'? laudo[2].indicacaoExameOutros: laudo[2].indicacaoExame}}.
        </div>
        <div v-else-if="elemento == 'IndicaExame3'">
          <strong>Indicação do exame:</strong> {{ laudo[3].indicacaoExame == 'Outros'? laudo[3].indicacaoExameOutros: laudo[3].indicacaoExame}}.
        </div>
        <div v-else-if="elemento == 'IndicaExame4'">
          <strong>Indicação do exame:</strong> {{ laudo[4].indicacaoExame == 'Outros'? laudo[4].indicacaoExameOutros: laudo[4].indicacaoExame}}.
        </div>

        <div v-else-if="elemento == 'EmbrCard0'">
          <strong>{{ laudo[0].embriaoEFeto }}</strong> identificado no interior
          do saco amniótico, com CCN medindo 
          {{ laudo[0].embriaoBpm }} mm. Atividade cardíaca
          {{ laudo[0].embriao }}.
        </div>
        <div v-else-if="elemento == 'EmbrCard1'">
          <strong>{{ laudo[1].embriaoEFeto }}</strong> identificado no interior
          do saco amniótico, com CCN medindo 
          {{ laudo[1].embriaoBpm }} mm. Atividade cardíaca
          {{ laudo[1].embriao }}.
        </div>
        <div v-else-if="elemento == 'EmbrCard2'">
          <strong>{{ laudo[2].embriaoEFeto }} </strong> identificado no interior
          do saco amniótico, com CCN medindo 
          {{ laudo[2].embriaoBpm }} mm. Atividade cardíaca
          {{ laudo[2].embriao }}.
        </div>
        <div v-else-if="elemento == 'EmbrCard3'">
          <strong>{{ laudo[3].embriaoEFeto }}</strong> identificado no interior
          do saco amniótico, com CCN medindo 
          {{ laudo[3].embriaoBpm }} mm. Atividade cardíaca
          {{ laudo[3].embriao }}.
        </div>
        <div v-else-if="elemento == 'EmbrCard4'">
          <strong>{{ laudo[4].embriaoEFeto }} </strong> identificado no interior
          do saco amniótico, com CCN medindo 
          {{ laudo[4].embriaoBpm }} mm. Atividade cardíaca
          {{ laudo[4].embriao }}.
        </div>

        <div v-else-if="elemento == 'FraseNegrito'">
          <strong
            >A ultrassonografia obstétrica em gestação inicial não permite o
            rastreamento ou diagnóstico de malformações fetais ou doenças
            cromossômicas/genéticas.
          </strong>
        </div>
        <div v-else-if="elemento == 'EmbrCardPresente0'">
          <strong>{{ laudo[0].embriaoEFeto }} </strong>identificado no interior
          do saco amniótico, com CCN medindo
          {{ laudo[0].embriaoBpm }} mm. Atividade cardíaca
          {{ laudo[0].embriao }}{{
            laudo[0].embriaoBpm2 ? ', com frequência de ' + laudo[0].embriaoBpm2 + ' bpm' : ''
          }}.
          
        </div>
        <div v-else-if="elemento == 'EmbrCardPresente1'">
          <strong>{{ laudo[1].embriaoEFeto }} </strong>identificado no interior
          do saco amniótico, com CCN medindo {{
            laudo[1].embriaoBpm
          }}
          mm. Atividade cardíaca {{ laudo[1].embriao }}
          {{
            laudo[1].embriaoBpm2 ? ', com frequência de ' + laudo[1].embriaoBpm2 + ' bpm' : ''
          }}.

          
        </div>
        <div v-else-if="elemento == 'EmbrCardPresente2'">
          <strong>{{ laudo[2].embriaoEFeto }} </strong>identificado no interior
          do saco amniótico, com CCN medindo
          {{ laudo[2].embriaoBpm }} mm. Atividade cardíaca
          {{ laudo[2].embriao }} -
          {{
            laudo[2].embriaoBpm2 ? ', com frequência de ' + laudo[2].embriaoBpm2 + ' bpm' : ''
          }}.

          
        </div>
        <div v-else-if="elemento == 'EmbrCardPresente3'">
          <strong>{{ laudo[3].embriaoEFeto }} </strong>identificado no interior
          do saco amniótico, com CCN medindo
          {{ laudo[3].embriaoBpm }} mm. Atividade cardíaca
          {{ laudo[3].embriao }} -
          {{
            laudo[3].embriaoBpm2 ? ', com frequência de ' + laudo[3].embriaoBpm2 + ' bpm' : ''
          }}.

         
        </div>
        <div v-else-if="elemento == 'EmbrCardPresente4'">
          <strong>{{ laudo[4].embriaoEFeto }} </strong>identificado no interior
          do saco amniótico, com CCN medindo
          {{ laudo[4].embriaoBpm }} mm. Atividade cardíaca
          {{ laudo[4].embriao }} -
          {{
            laudo[4].embriaoBpm2 ? ', com frequência de ' + laudo[4].embriaoBpm2 + ' bpm' : ''
          }}.

          
        </div>

        <div v-else>
          {{ elemento }}
        </div>
      </div>
      <br />
      <br />
      <footer v-if="indexFolha + 1 == tamanhoElemento.length">
        <span
          ><strong
            >Dra
            {{
              medico.nome == 'Adriana Melo (curso)'
                ? 'Adriana Melo'
                : medico.nome
            }}</strong
          > </span
        ><br />
        <span
          ><strong>CRM/PB- {{ medico.crm }} </strong></span
        >
      </footer>
    </folha>
  </section>
</template>

<script>
import ImprecoesMixins from '@mixins/ImprecoesMixins';
import moment from 'moment';
export default {
  mixins: [ImprecoesMixins],

  methods: {
    dataFormatada(val) {
      return moment(val).format('DD-MM-YYYY');
    },
  },

  mounted() {
    let folha = [];
    const todos = [];

    for (let i = 0; i < this.laudo.length; i++) {
      if (this.laudo.length > 1) folha.push('espaço');
      if (this.laudo.length > 1) folha.push('espaço');
      if (this.laudo.length > 1) folha.push('espaço');
      if (this.laudo.length > 1) folha.push(`feto${i}`);
      if (this.laudo.length > 1) folha.push('espaço');
      if (this.laudo.length > 1) folha.push('espaço');
      if (this.laudo.length > 1) folha.push('espaço');
      if (this.laudo.length > 1) folha.push('espaço');
      folha.push('espaço');

      if (this.laudo[i].indicacaoExame) folha.push(`IndicaExame${i}`);

      if (this.infosCompartilhadas.dataUltimaMestrucao)
        folha.push(
          `Data da última menstruação: ${this.dataFormatada(
            this.infosCompartilhadas.dataUltimaMestrucao
          )}.`
        );

      if (
        this.infosCompartilhadas.semanasDUM ||
        this.infosCompartilhadas.diasDUM
      )
        folha.push(
          `Idade gestacional (DUM) ${
            this.infosCompartilhadas.semanasDUM
              ? this.infosCompartilhadas.semanasDUM
              : ''
          }${
            this.infosCompartilhadas.semanasDUM
              ? this.infosCompartilhadas.semanasDUM > 1
                ? ' semanas'
                : ' semana '
              : ''
          }${
            this.infosCompartilhadas.semanasDUM &&
            this.infosCompartilhadas.diasDUM
              ? ' e '
              : ''
          }${
            this.infosCompartilhadas.diasDUM
              ? this.infosCompartilhadas.diasDUM
              : ''
          }${
            this.infosCompartilhadas.diasDUM
              ? this.infosCompartilhadas.diasDUM > 1
                ? ' dias'
                : ' dia'
              : ''
          }.`
        );

      if (
        this.laudo.length == 1 &&
        this.laudo[i].camposExibidos.exibeUteroGestUn
      )
        folha.push(`Gestação única. `);
      if (this.laudo.length == 2 && i == (this.laudo.length -1)) folha.push(`Gestação gemelar. `);
      if (this.laudo.length == 3 && i == (this.laudo.length -1)) folha.push(`Gestação trigemelar. `);
      if (this.laudo.length == 4 && i == (this.laudo.length -1)) folha.push(`Gestação quadrigemelar. `);
      if (this.laudo.length == 5 && i == (this.laudo.length -1)) folha.push(`Gestação quíntuplo. `);

      folha.push(`História materna`);
      if (this.laudo[i].dataNascimento)
        folha.push(`Data de nascimento : ${this.dataFormatada(this.laudo[i].dataNascimento)}.`);
        if (this.laudo[i].anos || this.laudo[i].meses)
        folha.push(`${this.laudo[i].anos?this.laudo[i].anos + (this.laudo[i].anos > 1 ? ' anos':' ano'):''}${
      this.laudo[i].anos && this.laudo[i].meses? ' e ':''}${
        this.laudo[i].meses?this.laudo[i].meses + (this.laudo[i].meses > 1 ? ' meses':' mês'):''}.`);
        if (this.laudo[i].peso)
        folha.push(`${this.laudo[i].peso?"Peso: " + this.laudo[i].peso + ' kg.':""}${
      this.laudo[i].altura?" Altura: " + this.laudo[i].altura + ' cm.':""}${
      this.laudo[i].imc?" IMC: " + this.laudo[i].imc :""}.`);
      if (this.laudo[i].perdaGestacional)
      folha.push(`Perda gestacional: ${this.laudo[i].perdaGestacional}${
        [
                    'perda com menos de 11 semanas',
                    'perda entre 11 e 15 semanas',
                    'perda entre 16 e 20 semanas',
                    'perda entre 21 e 28 semanas',
                    'perda entre 29 e 34 semanas',
                  ].includes(this.laudo[i].perdaGestacional)? '. Quantas: ' + 
                  this.laudo[i].quantasPercas  : ''
    }.`)
    if (this.laudo[i].doencaPreExistente)
      folha.push(`Doenças pré-existente: ${this.laudo[i].doencaPreExistente}${
    this.laudo[i].tipoDoencaPreExistente? ', '+  this.laudo[i].tipoDoencaPreExistente : ''}.`)
    if (this.laudo[i].fuma)
      folha.push(`Tabagismo durante a gravidez: ${this.laudo[i].fuma}.`)
      if (this.laudo[i].metodoContracepcao)
      folha.push(`Método de contracepção: ${this.laudo[i].metodoContracepcao}.`)
      folha.push('espaço')
      if (
        this.laudo[i].camposExibidos.exFraseSinais ||
        (this.laudo[i].camposExibidos.exibePolipo &&
          this.laudo[i].polipoEndocervical &&
          this.laudo[i].exibeUteroSituado &&
          this.laudo[i].uteroSituado)
      )
      folha.push('espaço')
        folha.push('espaço')
        folha.push(`ute${i}`);
        folha.push('espaço')
        

      // folha.push(`espaço`);
      // folha.push(`espaço`);
      // folha.push(`espaço`);
      if (folha.length > 0) folha.splice(0, 0, '');
      this.indicacao = [...folha];
      folha = [];

      // if (this.laudo[i].sacoGestacional)
      //   folha.push(
      //     `Saco gestacional ${this.laudo[i].sacoGestacional}, único, inserido na lâmina endometrial  ${this.laudo[i].sacoGestacional2} da cavidade uterina, com paredes ${this.laudo[i].sacoGestacional3}, e reação decidual
      //     ${this.laudo[i].sacoGestacional4} Diâmetro médio de ${this.laudo[i].diametroMedio} mm.`
      //   );
      folha.push(`Cavidade uterina`);
      if (this.laudo[0].temHematoma){
        let textResult = `Localizado na porção ${
            this.laudo[0].areaHematoma
          } do saco gestacional, ${
            this.laudo[0].corion ? 'acometendo o córion ' : ''
          }${this.laudo[0].corion}, medindo ${this.laudo[0].corion1} x ${
            this.laudo[0].corion2
          } mm${this.laudo[0].acometSacGest? (', acometendo ' + this.laudo[0].acometSacGest + ' do saco gestacional' ):''}.`
        folha.push(`Hematoma subcoriônico: ${this.laudo[0].temHematoma}.${this.laudo[0].temHematoma== 'Sim'? ' ' + textResult
      
        :'' }`);
      }
        
    
       
           
      

      // if (this.laudo[0].temHematoma == 'Sim')
      //   folha.push(
      //     ` Na porção ${
      //       this.laudo[0].areaHematoma
      //     } do saco gestacional, ${
      //       this.laudo[0].corion ? 'acometendo o córion ' : ''
      //     }${this.laudo[0].corion}, medindo ${this.laudo[0].corion1} x ${
      //       this.laudo[0].corion2
      //     } mm${this.laudo[0].acometSacGest? (', acometendo ' + this.laudo[0].acometSacGest + ' do saco gestacional' ):''}.`
      //   );

      if (
        (this.laudo[i].sacoGestacional && this.laudo[i].sacoGestacional3) ||
        (this.laudo[i].camposExibidos.exibeDiametro &&
          this.laudo[i].diametroMedio)
      )
        folha.push(
          `${
            this.laudo[i].sacoGestacional && this.laudo[i].sacoGestacional3
              ? `SacoGest${i}`
              : ''
          }`
        );
      // ${
      //     this.laudo[i].camposExibidos.exibeDiametro &&
      //     this.laudo[i].diametroMedio
      //       ? ` Diâmetro médio de ${this.laudo[i].diametroMedio} mm.`
      //       : ''
      //   }

      // if (this.laudo[i].camposExibidos.exFraseSinais2 === true)
      //   folha.push(`SacoAmn`);
        if (this.laudo[i].camposExibidos.exFraseSinais23 === true && this.laudo[i].fraseSinaisFetEmb)
        folha.push(`SacoAmnFeto`);
      // if (this.laudo[i].camposExibidos.exFraseSinais2 === true)
      //   folha.push(`espaço`);
      // if (this.laudo[i].camposExibidos.exFraseSinais2 === true)
      //   folha.push(`espaço`);
      // if (this.laudo[i].camposExibidos.exFraseSinais2 === true)
      //   folha.push(`espaço`);

      if (this.laudo[i].embriao && this.laudo[i].embriao != 'presente')
        folha.push(`EmbrCard${i}`);

      if (this.laudo[i].embriao && this.laudo[i].embriao === 'presente')
        folha.push(`EmbrCardPresente${i}`);

      if (
        (this.laudo[i].camposExibidos.exibevesiculaVitelinica &&
          this.laudo[i].vesiculaVitelinica &&
          this.laudo[i].vesiculaContornos) ||
        (this.laudo[i].camposExibidos.exibeConteudo &&
          this.laudo[i].vesiculaConteudo) ||
        (this.laudo[i].camposExibidos.exibeVesiculaDiametro &&
          this.laudo[i].vesiDiametro)
      )
        folha.push(`VesVit${i}`);
      if (this.laudo[i].vesiculaVitelinica === 'não identificada')
        folha.push(`VesiVite${i}`);

      if (folha.length > 0) folha.splice(0, 0, '');
      this.cavidadeUterina = [...folha];
      folha = [];

      //       if (this.laudo[i].camposExibidos.exibeRegiaoAnexiais === true)
      //         folha.push(`Ovários identificados de aspecto morfológico dentro da normalidade, destacando-se a
      // presença do corpo lúteo no parênquima ovariano direito esquerdo.`);

      if (this.laudo[i].corpoLuteo)
        folha.push(
          `Corpo lúteo localizado no parênquima ovariano ${this.laudo[i].corpoLuteo}. `
        );

      if (this.laudo[i].ovaDir || this.laudo[i].ovaEsq){
        folha.push(`espaço`);
        folha.push(`espaço`);
        folha.push(`Ovarios`);
        

      } 
      if (this.laudo[i].ovaDir) folha.push(`OvaDir${i}`);
      if (this.laudo[i].ovaEsq) folha.push(`OvaEsq${i}`);
      if (this.laudo[i].dopplerCol) folha.push(`DopCol${i}`);
      if (this.laudo[i].ovaEsq || this.laudo[i].ovaDir) folha.push(`espaço`);
      if (this.laudo[i].ovaEsq || this.laudo[i].ovaDir) folha.push(`espaço`);
      if (this.laudo[i].ovaEsq || this.laudo[i].ovaDir) folha.push(`espaço`);

      if (this.laudo[i].comentariosAdcionais)
        folha.push(`${this.laudo[i].comentariosAdcionais}`);
      if (this.laudo[i].liquidoLivre)
        folha.push(
          `${this.laudo[i].liquidoLivre} de líquido livre em escavação retrouterina e ou parauterinas. `
        );
      folha.push(`espaço`);
      folha.push(`espaço`);
      folha.push(`espaço`);

      if (folha.length > 0) folha.splice(0, 0, '');
      this.regioesAnexiais = [...folha];
      folha = [];

     

      if (this.laudo[0].gestacaoTopicaUnica && i == (this.laudo.length -1))
        folha.push(`Gestação ${this.laudo[0].gestacaoTopicaUnica === 'outros'?
        this.laudo[0].gestacaoTopicaUnicaOutros : this.laudo[0].gestacaoTopicaUnica
      }${ this.laudo[0].gestacaoTopicaUnica2? ', ' + this.laudo[0].gestacaoTopicaUnica2: ''}.`);

      if (this.laudo[0].biometriaEmbrionariaSemanas && i == (this.laudo.length -1)) 
        folha.push(
          `Idade gestacional pelo CCN de ${
            this.laudo[0].biometriaEmbrionariaSemanas
              ? this.laudo[0].biometriaEmbrionariaSemanas +
                (this.laudo[0].biometriaEmbrionariaSemanas > 1
                  ? ' semanas'
                  : ' semana')
              : ''
          }${
            this.laudo[0].biometriaEmbrionariaSemanas &&
            this.laudo[0].biometriaEmbrionariaDias
              ? ' e '
              : ''
          }${
            this.laudo[0].biometriaEmbrionariaDias
              ? this.laudo[0].biometriaEmbrionariaDias +
                (this.laudo[0].biometriaEmbrionariaDias > 1 ? ' dias' : ' dia')
              : ''
          }${
            this.laudo[0].compativelIncompativel
              ? ', ' +
                this.laudo[0].compativelIncompativel +
                ' com o datismo menstrual'
              : ''
          }${
            this.laudo[0].compativelIncompativel == 'incompatível'? ' (Erro de data, devendo ser considerada a data provável do parto pela biometria atual)' : ''
          }.`
        );
      if (this.laudo[0].dataProvavelParto  && i == (this.laudo.length -1))
        folha.push(
          `Data provável do parto pela biometria atual: ${this.dataFormatada(
            this.laudo[0].dataProvavelParto
          )}.`
        );
      if (this.laudo[0].camposExibidos.exibeEmbriaoeVesicula === true && i == (this.laudo.length -1))
        folha.push(`Embrião e vesícula vitelínica ainda não identificados.`);
      if (this.laudo[0].camposExibidos.exibeEmbriaoSo === true && i == (this.laudo.length -1))
        folha.push(`Embrião ainda não identificado.`);

      if (this.laudo[0].camposExibidos.exibeObitoEmbrionario === true && i == (this.laudo.length -1))
        folha.push(`Óbito embrionário.`);

      if (this.laudo[0].camposExibidos.exibeGestacaoAnembrionada === true && i == (this.laudo.length -1))
        folha.push(`Gestação anembrionada.`);

        

      if (this.laudo[0].camposExibidos.exibeAvaliacao7Dias === true && i == (this.laudo.length -1))
        folha.push(
          `Sugiro nova avaliação em 7 dias para adequada caracterização da vitalidade embrionária.`
        );
      if (this.laudo[0].camposExibidos.exibeAvaliacao14Dias === true && i == (this.laudo.length -1))
        folha.push(
          `Sugiro nova avaliação em 14 dias para adequada caracterização da vitalidade embrionária.`
        );
      if (this.laudo[0].camposExibidos.exibeDesenvolvimentoAdequado === true && i == (this.laudo.length -1))
        folha.push(`Desenvolvimento adequado para a idade gestacional.`);
      if (this.laudo[0].comentariosAdcionaisDois && i == (this.laudo.length -1))
        folha.push(`${this.laudo[0].comentariosAdcionaisDois}`);

        if(this.laudo.length < 2){
      folha.push(`espaço`);
      folha.push(`espaço`);
      folha.push(`espaço`);
      folha.push(`espaço`);
        }

      if (this.laudo[0].camposExibidos.exibeconclusaoFinal === true && i == (this.laudo.length -1))
        folha.push(`FraseNegrito`);

      if (folha.length > 0 ) folha.splice(0, 0, 'Informações adicionais');
      this.informacoesAdicionais = [...folha];
      folha = [];

      todos.push(...this.indicacao);
      todos.push(...this.cavidadeUterina);
      todos.push(...this.regioesAnexiais);
      todos.push(...this.informacoesAdicionais);
    }

    while (todos.length > 0) {
      this.tamanhoElemento.push(todos.splice(0, 70));
    }
  },
  data() {
    return {
      indicacao: [],
      cavidadeUterina: [],
      regioesAnexiais: [],
      informacoesAdicionais: [],
      tamanhoElemento: [],
      titulos: ['Indicação', 'Cavidade uterina', 'Regiões anexiais', 'História materna', 'Cavidade uterina`'],
    };
  },
};
</script>

<style lang="scss" scoped>
$default-spacer: 0px;

section {
  text-align: justify;
  margin-left: 10px;
  font-size: 11pt;
  font-family: 'Calibri';

  .header {
    display: flex;
    flex-direction: column;
  }

  h3 {
    text-align: justify;
    margin-left: 0px;
    margin: $default-spacer * 0;
    padding: 0px;
    line-height: 15px;
  }

  div {
    margin-left: 0px;
  }

  h4 {
    text-align: justify;
    margin-left: 0px;
    margin: 0px;
    margin-top: 0px;
    line-height: 5px;
  }

  p {
    word-wrap: break-word;
    text-align: justify;
    font-family: 'Calibri';
    margin-left: 0px;

    margin-top: 1x;
    padding: 2px;
  }
  br {
    display: block;
    margin-top: 0px;
    margin-left: 0px;
    line-height: 100%;
  }
  span {
    margin: $default-spacer * 0;
    margin-top: 15px;
    margin-left: 0px;
    line-height: 5px;
  }
  footer {
    //position: absolute;
    bottom: 0;
    margin-left: 0px;

    width: 80%;
    height: 100px;
    text-align: left;
    line-height: 20px;
  }
}
</style>
